import Config from "config";
import { history } from "config/history";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { fetchProfile } from "redux/auth";
import { type AppDispatch } from "redux/store";
import {
  clearAuthTokens,
  CO_ACCESS_TOKEN,
  getAuthTokens,
  logOut,
} from "utilities/auth";
import { updateAxiosInstanceToken } from "utilities/axios";
import { type JwtPayload, jwtDecode } from "utilities/jwt";
import { KCRole } from "utilities/type-helpers/userRoles";

type JWTTokenShape = JwtPayload & {
  realm_access: {
    roles: string[];
  };
};

const useAuth = () => {
  const isFirstRenderRef = useRef(true);

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const { pathname } = useLocation();

  const dispatch = useDispatch<AppDispatch>();

  const { accessToken } = getAuthTokens();

  const hasKCTokenRole = (role: KCRole) => {
    return accessToken
      ? jwtDecode<JWTTokenShape>(accessToken).realm_access.roles.includes(role)
      : false;
  };

  useEffect(() => {
    if (isFirstRenderRef.current) {
      isFirstRenderRef.current = false;

      if (accessToken) {
        dispatch(
          fetchProfile({
            cb: async (response) => {
              if (response.status === "DONE") {
                setIsAuthenticated(true);
              } else {
                history.push(`/public/error/?errorId=${response.id}`);
                await clearAuthTokens();
                console.error(response.data); // eslint-disable-line no-console
              }
            },
          })
        );
      } else {
        // no token - redirect to keycloak
        const loginReqState = JSON.stringify({
          companySlug: Config.customerKey,
          origin: window.location.origin,
          path: pathname,
          callbackUrl: `${window.location.origin}/public/casaconnect-return`,
        });

        const casaConnectURL = new URL(
          `${Config.casaconnectUrl}/realms/casasoft/protocol/openid-connect/auth`
        );
        casaConnectURL.searchParams.set("client_id", "casaone-app");
        casaConnectURL.searchParams.set(
          "redirect_uri",
          `${Config.apiUrl}/dev/redirect-to-company-app`
        );
        casaConnectURL.searchParams.set("state", loginReqState);
        casaConnectURL.searchParams.set("response_type", "code");
        casaConnectURL.searchParams.set("scope", "openid");

        window.location.href = casaConnectURL.toString();
      }
    }
  }, [accessToken, dispatch, pathname]);

  useEffect(() => {
    // NOTE: The storage event only fires if OTHER tabs updated localstorage
    const storageHandler = (e: StorageEvent) => {
      if (e.key === CO_ACCESS_TOKEN) {
        if (!accessToken) {
          logOut();
        } else {
          // make sure we always get the latest access tokens from the localStorage
          updateAxiosInstanceToken();
        }
      }
    };

    window.addEventListener("storage", storageHandler);

    return () => {
      window.removeEventListener("storage", storageHandler);
    };
  }, [accessToken]);

  return [isAuthenticated, hasKCTokenRole] as const;
};

export default useAuth;
